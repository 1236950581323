<template>
  <div class="result">
    <h2 class="mt-10 mb-5 text-center">
      <span class="half_line">{{$t('view_statistics_title')}}</span>
    </h2>

    <div
    
      v-if="userInfo.otp"
      class="mb-5 text--disabled text-center"
      style="font-size: 14px; font-weight: normal"
    >
      {{$t('view_statistics_title_text')}}
    </div>
    <div
    
      v-if="!userInfo.otp"
      class="mb-5 text--disabled text-center"
      style="font-size: 14px; font-weight: normal"
    >
      {{$t('view_statistics_title_text_setup_otp')}}
    </div>


    <v-row class="px-10">
      <v-col cols="12" class="text-center" 
      v-if="userInfo.otp">
        
      <v-btn class="mx-auto" color="grey " outlined @click="$externalLink($localData('info_url')+userInfo.user_hash)">
        <v-icon style="font-size:120%" left>mdi-television-guide</v-icon>
        {{$t('view_statistics_bt_user_stats')}}
      </v-btn>

      </v-col>
      <v-col cols="12" class="text-center"
      v-if="!userInfo.otp">
        
      <v-btn class="mx-auto" color="grey " outlined to="/setting">
        <v-icon style="font-size:120%" left>mdi-television-guide</v-icon>
        {{$t('view_statistics_bt_setting')}}
        
      </v-btn>

      </v-col>


      <v-col cols="12" class="text-center">
<v-img
 max-width="600" style="display:inline-block;"
          :src="$store.state.systemOption.server_url+'/img/app/my_stats_blur.png'"  class="rounded md" />
      </v-col>

    </v-row>


    <v-row class="px-10" v-if="$store.state.stats">
<!--
      <v-col cols="6">
        <v-card class="mx-auto text-center">
          <v-card-title style="width: 100%; display: block; font-weight: bold">
            {{$nvl($store.state.stats.today_win_count,0)}}
          </v-card-title>
          <v-card-subtitle>  {{$t('view_result_today_win_count')}} </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="mx-auto text-center">
          <v-card-title style="width: 100%; display: block; font-weight: bold">
            {{$nvl($store.state.stats.total_loss_count,0)}}
          </v-card-title>
          <v-card-subtitle> {{$t('view_result_today_loss_count')}} </v-card-subtitle>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card class="mx-auto text-center">
          <v-card-title style="width: 100%; display: block; font-weight: bold">
            {{$nvl($store.state.stats.total_win_count,0)}}
          </v-card-title>
          <v-card-subtitle> {{$t('view_result_total_profit_count')}} </v-card-subtitle>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card class="mx-auto text-center">
          <v-card-title style="width: 100%; display: block; font-weight: bold">
            {{$nvl($store.state.stats.total_locount,0)}}
          </v-card-title>
          <v-card-subtitle> {{$t('view_result_total_loss_count')}} </v-card-subtitle>
        </v-card>
      </v-col>
-->


<!-- 범위는 이제 제외
      <v-col cols="6">
        <v-card class="mx-auto text-center">
          <v-card-title style="width: 100%; display: block; font-weight: bold">
            {{$addComma($setFloat( $nvl($store.state.stats.total_profit_range,0) , 2))}}
          </v-card-title>
          <v-card-subtitle> {{$t('view_result_total_profit_range')}} </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="mx-auto text-center">
          <v-card-title style="width: 100%; display: block; font-weight: bold">
            {{$addComma($setFloat($nvl($store.state.stats.today_profit_range,0) ,2))}}
          </v-card-title>
          <v-card-subtitle> {{$t('view_result_today_profit_range')}} </v-card-subtitle>
        </v-card>
      </v-col>
범위는 이제 제외-->



    </v-row>


    <div style="max-width: 320px" class="mx-auto mt-10" v-if="false">
      <v-btn type="submit" block text outlined color="grey">
        {{$t('view_result_stats_reset')}}
      </v-btn>
    </div>



  </div>
</template>

<script lang="ts">
import Vue from "vue";
//import HelloWorld from "../components/HelloWorld.vue";

export default Vue.extend({
  name: "Result",

  components: {
    //HelloWorld,
  },
  computed: {
    systemOption() {
      return this.$store.state.systemOption;
    },
    userInfo() {
      return this.$store.state.userinfo;
    },
  },
  data: () => ({
    labels: [
      "22.01.01",
      "22.01.02",
      "22.01.03",
      "22.01.04",
      "22.01.05",
      "22.01.06",
      "22.01.07",
      "22.01.08",
    ],
    value: [200, 250, 240, 310, 410, 390, 460, 675],
  }),

  created(){

      let systemOption = this.$localData('systemOption');
      let userInfo = this.$localData('userInfo');
        let payload = {
            "user_id": userInfo.user_id,
            "exchange": systemOption.exchange,
            //"symbol": systemOption.symbol
        }
        this.$getStats(payload);

  }
});
</script>
